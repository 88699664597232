<template>
  <b-card no-body>
    <b-card-body class="text-right">
      <b-button
        variant="primary"
        :to="$route.path + '/add'"
      >
        <FeatherIcon icon="PlusIcon" /> Oluştur
      </b-button>
    </b-card-body>
    <b-table
      class="mt-1"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(title)="data">
        {{ data.item.title }}
      </template>
      <template #cell(status)="data">
        <FeatherIcon
          :class="data.item.status === '1'? 'text-success' : 'text-warning'"
          :icon="data.item.status === '1'? 'CheckIcon' : 'ClockIcon'"
        />
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          :to="$route.path + '/view/' + data.item.id"
          size="sm"
        >
          Görüntüle
        </b-button>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BCard, BTable, BPagination, BButton, BCardFooter, BCardBody,
} from 'bootstrap-vue'

const tableName = 'supports'
export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BCardBody,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'subject',
          label: 'KONU',
        },
        {
          key: 'project',
          label: 'PROJE',
        },
        {
          key: 'support_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.subject AS subject`,
          'support_statuses.title AS support_status',
          'projects.title AS project',
          'priorities.title AS priority',
          'customer_users.name AS customer_user',
        ],
        order_by: ['supports.id', 'DESC'],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['clientSupports/dataList']
    },
    dataCounts() {
      return this.$store.getters['clientSupports/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 10
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.dataQuery.where = {
        'supports.id_customer_users': this.userData.id,
      }
      this.$store.dispatch('clientSupports/getDataList', this.dataQuery)
    },
  },
}
</script>
